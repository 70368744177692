import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import * as oneClickApi from "../api/OneClickApi";
import Logo from './Logo';

const OneClickPurchase = () => {

    const phoneInputName = "borrowerCellPhone";
    const tcpaInputName = "tcpaCheckbox";
    const { id } = useParams();
    const history = useHistory();
    const [customer, setCustomer] = useState({
        UhmIdHash: '',
        borrowerCellPhone: '',
        cellPhoneModified: false,
        channel: '',
        tcpaChecked: false,
        submitted: false
    });
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        CustomerLanded(id);
    }, []);

    const CustomerLanded = (id) => {
        setLoading(true);
        oneClickApi.getCustomerData(id)
            .then(response => {
                setLoading(false);
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Error returning customer");
                }
            })
            .then(data => {
                data.borrowerCellPhone = maskPhoneNumber(data.borrowerCellPhone);
                setCustomer({ ...data });
                validateFormOnLoad(data);
                submitCustomerLanded(id);
            })
            .catch(() => {
                history.push("/error");
            });
    }

    const submitCustomerLanded = (id) => {
        oneClickApi.submitCustomerLanded(id)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Error posting customer landed");
                }
            })
            .catch(() => {
                history.push("/error");
            });
    }

    const validateFormOnLoad = (customer) => {
        const borrowerCellPhone = customer.borrowerCellPhone;

        if (!borrowerCellPhone) {
            validatePhone(borrowerCellPhone, errors);
        }
        setErrors({ ...errors });
    }

    const validateForm = () => {
        const { borrowerCellPhone, cellPhoneModified, tcpaChecked } = customer;
        if (cellPhoneModified) {
            validatePhone(borrowerCellPhone, errors);
        }
        validateTcpaChecked(tcpaChecked, errors);
        setErrors({ ...errors });
        return Object.keys(errors).length === 0;
    }

    const validatePhone = (phone, errors) => {
        var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phoneRegex.test(phone)) {
            errors.phoneNumber = "Please enter a valid phone number.";
        } else {
            delete errors.phoneNumber
        }
    }

    const validateTcpaChecked = (tcpaChecked, errors) => {
        if (!tcpaChecked) {
            errors.tcpaChecked = "Please check the box to accept the terms above.";
        } else {
            delete errors.tcpaChecked
        }
    }

    const maskPhoneNumber = (phone) => {
        //Strip out masking characters...
        phone = phone?.replace(/$| |\*|\(|\)|-/gi, '');

        //Make sure we're only entering numbers and limiting length...
        if (isNaN(phone) || phone.length > 10) {
            return;
        };

        //Apply phone number mask.
        let maskedPhoneNumber = ""

        if (phone.length <= 3) {
            maskedPhoneNumber = phone;
        }
        if (phone.length >= 4 && phone.length <= 6) {
            maskedPhoneNumber = phone.substr(0, 3) + "-" + phone.substr(3, 3);
        }
        if (phone.length >= 7) {
            maskedPhoneNumber += phone.substr(0, 3) + "-" + phone.substr(3, 3) + "-" + phone.substr(6);
        }

        return maskedPhoneNumber;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) return;

        setSaving(true);
        var response = await oneClickApi.submitCustomerData(customer);
        setSaving(false);

        if (response.ok) {
            setCustomer({ ...customer, submitted: true });
        }
        else {
            const errorResponse = await response.json();
            handleErrors(errorResponse);
        };
    }

    const handleFocus = (event) => {
        switch (event.target.name) {
            case phoneInputName:
                setCustomer({
                    ...customer,
                    [event.target.name]: "",
                    cellPhoneModified: true
                });
                break;
        }
    }

    const handleChange = (event) => {
        var name = event.target.name;
        var value = event.target.value;

        switch (event.target.name) {
            case phoneInputName:
                //Strip out masking characters...
                value = value.replace(/$| |\*|\(|\)|-/gi, '');

                //Make sure we're only entering numbers and limiting length...
                if (isNaN(value) || value.length > 10) {
                    return;
                };

                setCustomer({
                    ...customer,
                    [name]: maskPhoneNumber(value),
                    cellPhoneModified: true
                });
                break;

            case tcpaInputName:
                setCustomer({
                    ...customer,
                    tcpaChecked: event.target.checked
                });
                validateTcpaChecked(event.target.checked, errors);
                break;
        }
    };

    const handleBlur = (event) => {
        const value = event.target.value;
        switch (event.target.name) {
            case phoneInputName:
                validatePhone(value, errors);
                break;
        }
        setErrors({ ...errors });
    }

    const handleErrors = (response) => {
        if (response.TcpaChecked) {
            setCustomer({ ...customer, tcpaChecked: false });
            validateTcpaChecked(false, errors);
        }

        setErrors({ ...errors });
    }

    const Message = ({ messageTitle }) => {
        return (
            <React.Fragment>
                <div class="loading-message">
                    <div>{messageTitle}</div>
                    <div><Spinner className="spinner"></Spinner></div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <form onSubmit={handleSubmit}>
            <div class="wrapper">
                <Logo />
                <div class="content">
                    {/* Form section...*/}
                    {!customer.submitted && (
                        <React.Fragment>
                            <h1><span>Let's get started!</span><br />UHM can guide you home.</h1>
                            <h2>Please verify or enter your phone number below.</h2>
                            <div class="fields">
                                <label for="phone" aria-label="phone">Phone</label>
                                <input id="phone" placeholder="Please enter a valid phone number" type="text" name="borrowerCellPhone" value={customer.borrowerCellPhone} onFocus={handleFocus} onChange={handleChange} onBlur={handleBlur} maxLength={12} />
                                <div className="text-danger">{errors.phoneNumber}</div>
                                <div class="tcpaCheckbox">
                                    <input type="checkbox" id="tcpaCheckbox" name="tcpaCheckbox" checked={customer.tcpaChecked} value={customer.tcpaChecked} onChange={handleChange} />
                                    <label for="tcpaCheckbox">
                                        I understand that checking this box constitutes my electronic signature and I expressly consent to receive promotional text messages and phone calls from Union Home Mortgage Corp. to the above mobile phone number.
                                    </label>
                                    <div className="text-danger">{errors.tcpaChecked}</div>
                                </div>
                            </div>
                            <button class="button" id="submit" aria-label="submit">Get Started</button>
                        </React.Fragment>
                    )}

                    {/* Confirmation section... */}
                    {customer.submitted && (
                        <React.Fragment>
                            <div class="confirmation-message">
                                <h1><span>Got it!</span><br />
                                    {customer.channel === "Retail" ?
                                        "Your mortgage expert will be in touch." :
                                        "One of our mortgage experts will be in touch."}
                                </h1>
                            </div>
                        </React.Fragment>
                    )}

                    {/* Consumer Direct contact section... */}
                    {customer.channel !== "Retail" && (
                        <a href="tel:1-877-846-4968">
                            <div class="contact">Have questions?<br />
                                Call us at 1-877-846-4968
                            </div>
                        </a>
                    )}

                    <div class="footer">
                        <img src="https://www.uhm.com/images/uhm/uhm-logo-color.svg" alt="Union Home Mortgage logo" />
                        <div>
                            NMLS #2229, NMLS Consumer Access website: www.nmlsconsumeraccess.org. Union Home Mortgage Corp. is an Equal Housing Lender. Loans are available on a fair and equal basis regardless of race, color, national origin, religion, sex, handicap, marital status, sexual orientation, gender identity, familial status (having children under the age of 18), age (if old enough to enter a contract), because income is from public assistance, or because a right was exercised under the Consumer Credit Protection Act.
                        </div>
                    </div>
                </div>
            </div>
            <div class="purchaseBackgroundImg"></div>

            {/* Loading message... */}
            {loading && <Message messageTitle="Loading your information" />}

            {/* Saving message... */}
            {saving && <Message messageTitle="Saving your information" />}

        </form>
    );
}

export default OneClickPurchase;