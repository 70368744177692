
const controllerName = "/api/ZuhmClickRefi";

export async function getCustomerData(id) {
    return await fetch(`${controllerName}/${id}`);
}

export async function submitCustomerLanded(id) {
    return await fetch(`${controllerName}/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        }
    });
}

export async function submitCustomerData(customer) {
    return await fetch(`${controllerName}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json, text/plain',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        body: JSON.stringify(customer)
    });
}

 