import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import OneClick from './components/OneClick';
import OneClickPurchase from './components/OneClickPurchase';
import OneClickOffer from './components/OneClickOffer';
import OneClickCashOut from './components/OneClickCashOut';
import { Error } from './components/Error';
import { Lost } from './components/Lost';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <Route path='/1click/:id' component={OneClick} exact/>
                    <Route path='/1click-purchase/:id' component={OneClickPurchase} exact/>
                    <Route path='/1click-offer/:id' component={OneClickOffer} exact/>
                    <Route path='/1click-cashout/:id' component={OneClickCashOut} exact/>
                    <Route path='/error' component={Error} />
                    <Route component={Lost} />
                </Switch>
            </Layout>
        );
    }
}
