import React, { Component } from 'react';
import Logo from './Logo';

export class Error extends Component {
    static displayName = Error.name;

    constructor(props) {
        super(props);
        this.state = { };
    }

    componentDidMount() {

    }

    render() {

        return (
            <div>
                <div class="wrapper">
                    <Logo/>
                    <div class="content">
                        <h1>We're sorry, something went wrong</h1>
                        <h2 class="">Please close this window and try again using the email link. If the problem persists please reach out to us at <span><a href="tel:1-877-846-4968">1-877-846-4968</a></span></h2>
                    </div>
                    <div class="footer">
                        <img src="https://www.uhm.com/images/uhm/uhm-logo-color.svg" alt="Union Home Mortgage logo" />
                        <div>
                            NMLS #2229, NMLS Consumer Access website: www.nmlsconsumeraccess.org. Union Home Mortgage Corp. is an Equal Housing Lender. Loans are available on a fair and equal basis regardless of race, color, national origin, religion, sex, handicap, marital status, sexual orientation, gender identity, familial status (having children under the age of 18), age (if old enough to enter a contract), because income is from public assistance, or because a right was exercised under the Consumer Credit Protection Act.
                        </div>
                    </div>
                </div>
                <div class="errorImg"></div>
            </div>
        );
    }
}
